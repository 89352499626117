import { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    FaUsers, FaThLarge, FaGlobe, FaPen, FaFileAlt,
    FaStar, FaMoneyBill, FaSignOutAlt, FaBars, FaGraduationCap
} from 'react-icons/fa';


import './sidemenu.css';
import useWindowDimensions from '../../utils/WindowDimension';
import useOnClickOutside from "../../utils/useOnClickOutside";
import { MenuLogo, MenuMobLogo } from './SideMenu.Styled';
import { useAuth } from '../../context/AuthContext';

const SideMenu = () => {

    const { logout } = useAuth();
    const { width } = useWindowDimensions();
    const [showMenu, setShowMenu] = useState(false);
    const { pathname } = useLocation();

    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    useOnClickOutside(menuRef, () => setShowMenu(false));

    const toggleMenu = () => {
        setShowMenu(prevState => !prevState);
    };

    const handleLogout = async () => {
        await logout();
        window.location.reload();
    };

    const renderMenuLinks = () => (
        <>
            <Link to="/" className="header-menu-button"
                style={pathname === "/" ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                <FaUsers style={{ marginRight: 16 }} /> Accounts
            </Link>
            <Link to="/platform" className="header-menu-button"
                style={pathname === "/platform" ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                <FaThLarge style={{ marginRight: 16 }} /> Platform
            </Link>
            <Link to="/localization" className="header-menu-button"
                style={pathname === "/localization" ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                <FaGlobe style={{ marginRight: 16 }} /> Localization
            </Link>
            <Link to="/blog" className="header-menu-button"
                style={(pathname === "/blog" || pathname.includes("/post")) ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                <FaPen style={{ marginRight: 16 }} /> Blog
            </Link>
            <Link to="/templates" className="header-menu-button"
                style={(pathname === "/templates" || pathname.includes("edit-template")) ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                <FaFileAlt style={{ marginRight: 16 }} /> CV Templates
            </Link>
            <Link to="/reviews" className="header-menu-button"
                style={pathname === "/reviews" ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                <FaStar style={{ marginRight: 16 }} /> Reviews
            </Link>
            <Link to="/course" className="header-menu-button"
                style={(pathname === "/course" || pathname.includes("/lesson")) ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                <FaGraduationCap style={{ marginRight: 16 }} /> Course
            </Link>
            <Link to="/payments" className="header-menu-button"
                style={pathname === "/payments" ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                <FaMoneyBill style={{ marginRight: 16 }} /> Payments
            </Link>
            <button className="header-menu-button" style={{
                border: 'none',
                background: 'none',
                cursor: 'pointer'
            }} onClick={handleLogout}>
                <FaSignOutAlt style={{ marginRight: 16 }} /> Logout
            </button>
        </>
    );

    return (
        <div className={width <= 1366 ? "header__container" : "side__container"}>
            {width <= 1366 ? (
                <div className="logo__container">
                    <MenuMobLogo src={require('../../assets/logo1.png')} />
                    <button
                        ref={buttonRef}
                        className="header-menu"
                        onClick={toggleMenu}
                        aria-expanded={showMenu}
                    >
                        <FaBars style={{ width: 45, paddingTop: 2 }} />
                    </button>
                    {showMenu && (
                        <div ref={menuRef} className="menu">
                            {renderMenuLinks()}
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <MenuLogo src={require('../../assets/logo1.png')} />
                    {renderMenuLinks()}
                </div>
            )}
        </div>
    );
};

export default SideMenu;