import React, { createContext, useContext, useState, useEffect } from 'react';
import { requestLogout } from '../services/auth';
import { baseUrl } from '../constants';
import { isAuthenticated as isAuthenticatedState } from '../recoil/storeTS';
import { useRecoilState } from 'recoil';

interface AuthContextType {
    isAuthenticated: boolean;
    logout: () => void;
    authenticate: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useRecoilState<boolean>(isAuthenticatedState);

    const checkAuthFromCookie = () => {
        const refreshToken = document.cookie.split('; ').find(row => row.startsWith('token.refresh='));
        setIsAuthenticated(!!refreshToken);
    };

    useEffect(() => {
        if (baseUrl === window.location.origin) {
            console.log('Checking auth from cookie');
            checkAuthFromCookie();
        }
    }, []);

    const clearCookies = () => {
        document.cookie.split(';').forEach(cookie => {
            const name = cookie.split('=')[0].trim();
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        });
    };

    const logout = async () => {
        await requestLogout();
        clearCookies();
        setIsAuthenticated(false);
        window.location.href = '/';
    };

    const authenticate = () => {
        setIsAuthenticated(true);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, logout, authenticate }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};