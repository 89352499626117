import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    background-color: #F9F9FC;
    min-width: 1280px;
    min-height: 100vh;
    width: 100%;
    
    @media (max-width: 1366px) {
        padding-top: 128px;
    }
`

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 30px;
    width: 100%;
    border-radius: 4px;
    padding: 30px 54px 30px 54px;
    @media(max-width: 1162px){
        padding: 30px 40px 30px 40px;
    }
    @media(max-width: 735px){
        padding: 30px 20px 30px 20px;
    }
`
export const ListId = styled.p`
    width: 4.9%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #B5B7C0;
    margin-right: 10px;
    word-break: break-word;
`
export const Input = styled.input`
    box-sizing: border-box;
    width: 100%;
    background: #F5F6FA;
    border: none;
    border-radius: 4px;
    padding: 15px;
    margin: 10px 0px 10px 0px;
    font-family: 'Lexend';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.019em;
    color: #292D32;
`
export const Textarea = styled.textarea`
    box-sizing: border-box;
    width: 100%;
    background: #F5F6FA;
    border: none;
    border-radius: 4px;
    padding: 15px;
    margin: 10px 0px 10px 0px;
    font-family: 'Lexend';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.019em;
    color: #292D32;
`
export const Label = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.019em;
    color: #292D32;
`
export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
export const TextArea = styled.textarea`
    box-sizing: border-box;
    width: 100%;
    height: 120px;
    background: #F5F6FA;
    border: none;
    border-radius: 4px;
    padding: 15px;
    margin: 10px 0px 10px 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.019em;
    color: #292D32;
    resize: vertical;
`
export const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`
export const BUTTON = styled.button`
    color:  ${props => props?.clr};
    font-size: ${props => props?.f_size};
    font-weight: ${props => props?.wei};
    font-family: ${props => props?.f_family};
    display:  ${props => props?.d_play};
    padding: ${props => props?.pad};
    border-radius: ${props => props?.brad};
    background-color: ${props => props?.dis ? props?.dis : props?.bg_color};
    border: ${props => props?.bord == "0" ? 0 : props?.bord};
    min-width: ${props => props?.m_wid};
    width: ${props => props?.wid};
    height: ${props => props?.hei};
    &:hover {
        color: ${props => props.hov && props.hov?.color};
    }
`
