import { atom } from "recoil";
import { ActiveClickableComponent, Blog, BlogContentItem } from "../pages/types";

export const isAuthenticated = atom<boolean>({
    key: 'isAuthenticated',
    default: false,
});

export const blog = atom<Blog | null>({
    key: 'blog',
    default: null,
});

export const lesson = atom<{ name: string, content: BlogContentItem[] } | null>({
    key: 'lesson',
    default: {
        name: '',
        content: [],
    },
});

export const activeEditor = atom<number | null>({
    key: 'activeEditor',
    default: null,
});

export const activeClickableComponent = atom<ActiveClickableComponent | null>({
    key: 'activeClickableComponent',
    default: null,
});
